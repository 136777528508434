export default class Chart1 {
    constructor() {}

    transition(bool) {
        if (bool) {
            document.querySelector(".chart-2").classList.add("fade-in")
            document.querySelector(".bar-france").style.height = "400px"
            document.querySelector(".bar-croatia").style.height = "133px"
        } else {
            document.querySelector(".chart-2").classList.remove("fade-in")
            document.querySelector(".bar-france").style.height = "0px"
            document.querySelector(".bar-croatia").style.height = "0px"
        }
    }
}
