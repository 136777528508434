export default class AudioManager {
    constructor(perspective) {
        this.ambient = new Audio(require("./assets/audio/ambient-loop.mp3"))
        this.audio1 = new Audio(require("./assets/audio/img-1a.mp3"))
        this.audio1_2 = new Audio(require("./assets/audio/img-1b.mp3"))
        this.audio2 = new Audio(require("./assets/audio/img-2.mp3"))
        this.audio3 = new Audio(require("./assets/audio/img-3.mp3"))
        this.audio4 = new Audio(require("./assets/audio/img-4.mp3"))
        this.audio5 = new Audio(require("./assets/audio/img-5.mp3"))
        this.audio6 = new Audio(require("./assets/audio/img-6.mp3"))
        this.audio7 = new Audio(require("./assets/audio/img-7.mp3"))
        this.audio8 = new Audio(require("./assets/audio/img-8.mp3"))
        this.audio9 = new Audio(require("./assets/audio/img-9.mp3"))
        this.audio10 = new Audio(require("./assets/audio/img-10.mp3"))

        this.perspective = perspective
    }

    playIntroduction() {
        this.audio1.play()
        this.audio1 = null
    }

    playAmbient() {
        this.ambient.volume = 0.04
        this.ambient.addEventListener(
            "ended",
            () => {
                this.ambient.currentTime = 0
                this.ambient.volume = 0.04
                this.ambient.play()
            },
            false
        )
        this.ambient.play()
    }

    play(z) {
        if (Math.round(z) > this.perspective - 300 && this.audio1_2 !== null) {
            this.audio1_2.play()
            this.audio1_2 = null
        }

        if (Math.round(z) > this.perspective + 230 && this.audio2 !== null) {
            this.audio2.play()
            this.audio2 = null
        }

        if (Math.round(z) > this.perspective * 2 && this.audio3 !== null) {
            this.audio3.play()
            this.audio3 = null
        }

        if (Math.round(z) > this.perspective * 3 + 50 && this.audio4 !== null) {
            this.audio4.play()
            this.audio4 = null
        }

        if (Math.round(z) > this.perspective * 4 + 50 && this.audio5 !== null) {
            this.audio5.play()
            this.audio5 = null
        }

        if (Math.round(z) > this.perspective * 5 + 50 && this.audio6 !== null) {
            this.audio6.play()
            this.audio6 = null
        }

        if (Math.round(z) > this.perspective * 6 + 50 && this.audio7 !== null) {
            this.audio7.play()
            this.audio7 = null
        }

        if (Math.round(z) > this.perspective * 7 && this.audio8 !== null) {
            this.audio8.play()
            this.audio8 = null
        }

        if (Math.round(z) > this.perspective * 8 + 50 && this.audio9 !== null) {
            this.audio9.play()
            this.audio9 = null
        }

        if (Math.round(z) > this.perspective * 9 + 50 && this.audio10 !== null) {
            this.audio10.play()
            this.audio10 = null
        }
    }

    cancelAll() {
        this.audio1 = null
        this.audio1_2 = null
        this.audio2 = null
        this.audio3 = null
        this.audio4 = null
        this.audio5 = null
        this.audio6 = null
        this.audio7 = null
        this.audio8 = null
        this.audio9 = null
    }
}
