export default class Chart5 {
    constructor() {}

    transition(bool) {
        if (bool) {
            document.querySelector(".chart-6").classList.add("fade-in")
            document.querySelector(".score-1").style.width = "300px"
            document.querySelector(".score-2").style.width = "250px"
            document.querySelector(".score-3").style.width = "200px"
            document.querySelector(".score-4").style.width = "150px"
        } else {
            document.querySelector(".chart-6").classList.remove("fade-in")
            document.querySelector(".score-1").style.width = 0
            document.querySelector(".score-2").style.width = 0
            document.querySelector(".score-3").style.width = 0
            document.querySelector(".score-4").style.width = 0
        }
    }
}
