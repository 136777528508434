import img1 from "./assets/img/img-1.png"
import img2 from "./assets/img/img-2.png"
import img3 from "./assets/img/img-3.png"
import img4 from "./assets/img/img-4.png"
import img5 from "./assets/img/img-5.png"
import img6 from "./assets/img/img-6.png"
import img7 from "./assets/img/img-7.png"
import img8 from "./assets/img/img-8.png"
import img9 from "./assets/img/img-9.png"
import img10 from "./assets/img/img-10.png"

import video from "./assets/introduction.mp4"

export default class Loader {
    constructor() {
        this.importedUrl = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10]
        this.loading = 0

        for (let i = 0; i < this.importedUrl.length; i++) {
            let imageUrl = this.importedUrl[i]
            let image = new Image()
            image.onload = () => {
                document.querySelector(`.img-${i + 1}`).style.backgroundImage = `url('${image.src}')`
                this.loading = this.loading + 7.5
                this.updateLoader()
            }
            image.src = imageUrl
        }

        this.videoUrl = video
        this.video = document.createElement("video")
        this.video.oncanplaythrough = () => {
            document.getElementById("source").setAttribute("src", `${this.video.src}`)
            this.loading = this.loading + 25
            this.updateLoader()
        }
        this.video.src = this.videoUrl
    }

    updateLoader() {
        document.querySelector(".loading-line").style.width = `${this.loading}vw`
        document.getElementById("loader").innerHTML = this.loading + "%"
        if (this.loading === 100) {
            document.querySelector(".footer").classList.add("fade-out")
        }
    }
}
