export default class Chart6 {
    constructor() {}

    transition(bool) {
        if (bool) {
            document.querySelector(".chart-7").classList.add("fade-in")

            document.querySelector(".stats-1").style.width = "400px"
            document.querySelector(".stats-2").style.width = "400px"
            document.querySelector(".stats-3").style.width = "400px"
            document.querySelector(".stats-4").style.width = "400px"
            document.querySelector(".stats-5").style.width = "400px"

            document.querySelector(".france-stats-1").style.width = "30%"
            document.querySelector(".france-stats-2").style.width = "33%"
            document.querySelector(".france-stats-3").style.width = "14%"
            document.querySelector(".france-stats-4").style.width = "20%"
            document.querySelector(".france-stats-5").style.width = "33.5%"
        } else {
            document.querySelector(".chart-7").classList.remove("fade-in")

            document.querySelector(".stats-1").style.width = "0"
            document.querySelector(".stats-2").style.width = "0"
            document.querySelector(".stats-3").style.width = "0"
            document.querySelector(".stats-4").style.width = "0"
            document.querySelector(".stats-5").style.width = "0"

            document.querySelector(".france-stats-1").style.width = "0%"
            document.querySelector(".france-stats-2").style.width = "0%"
            document.querySelector(".france-stats-3").style.width = "0%"
            document.querySelector(".france-stats-4").style.width = "0%"
            document.querySelector(".france-stats-5").style.width = "0%"
        }
    }
}
