export default class Chart3 {
    constructor() {}

    transition(bool) {
        if (bool) {
            document.querySelector(".chart-4").classList.add("fade-in")
            document.querySelector(".france-1").style.width = "38%"
            document.querySelector(".france-2").style.width = "14%"
            document.querySelector(".france-3").style.width = "40%"
        } else {
            document.querySelector(".chart-4").classList.remove("fade-in")
            document.querySelector(".france-1").style.width = "0%"
            document.querySelector(".france-2").style.width = "0%"
            document.querySelector(".france-3").style.width = "0%"
        }
    }
}
