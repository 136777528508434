export default class Chart4 {
    constructor() {}

    transition(bool) {
        if (bool) {
            document.querySelector(".chart-5").classList.add("chart-5-trans")
        } else {
            document.querySelector(".chart-5").classList.remove("chart-5-trans")
        }
    }
}
