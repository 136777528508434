export default class Chart8 {
    constructor() {}

    transition(bool) {
        if (bool) {
            document.querySelector(".chart-9").classList.add("fade-in")
            document.querySelector(".player-1").style.width = "300px"
            document.querySelector(".player-2").style.width = "250px"
            document.querySelector(".player-3").style.width = "200px"
        } else {
            document.querySelector(".chart-9").classList.remove("fade-in")
            document.querySelector(".player-1").style.width = "0"
            document.querySelector(".player-2").style.width = "0"
            document.querySelector(".player-3").style.width = "0"
        }
    }
}
