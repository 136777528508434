export default class Chart10 {
    constructor() {}

    transition(bool) {
        if (bool) {
            document.querySelector(".chart-11").classList.add("fade-in")
            document.querySelector(".winner-1").style.height = "300px"
            document.querySelector(".winner-2").style.height = "240px"
            document.querySelector(".winner-3").style.height = "120px"
            document.querySelector(".winner-4").style.height = "80px"
        } else {
            document.querySelector(".chart-11").classList.remove("fade-in")
            document.querySelector(".winner-1").style.height = "0px"
            document.querySelector(".winner-2").style.height = "0px"
            document.querySelector(".winner-3").style.height = "0px"
            document.querySelector(".winner-4").style.height = "0px"
        }
    }
}
