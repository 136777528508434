export default class Chart2 {
    constructor() {}

    transition(bool) {
        if (bool) {
            document.querySelector(".chart-3").classList.add("chart-3-trans")
        } else {
            document.querySelector(".chart-3").classList.remove("chart-3-trans")
        }
    }
}
