export default class ScoreManager {
    constructor() {
        this.scoreBarDOM = document.querySelector(".background-france")
        this.franceScoreDOM = document.querySelector(".score-france")
        this.croatiaScoreDOM = document.querySelector(".score-croatia")
    }

    updateScore(franceScore, croatiaScore) {
        if (franceScore === 0 && croatiaScore === 0) {
            this.scoreBarDOM.style.width = 500 / 2 + "px"
        } else if (franceScore === 1 && croatiaScore === 0) {
            this.scoreBarDOM.style.width = 499 + "px"
        } else {
            this.scoreBarDOM.style.width = (500 / (croatiaScore + franceScore)) * franceScore + "px"
        }
        this.franceScoreDOM.innerHTML = `${franceScore}`
        this.croatiaScoreDOM.innerHTML = `${croatiaScore}`
    }

    updateStyle(currentImg) {
        if (currentImg === 2) {
            this.scoreBarDOM.classList.add("border-radius")
        } else {
            this.scoreBarDOM.classList.remove("border-radius")
        }
    }
}
