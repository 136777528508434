export default class ImgManager {
    constructor() {
        this.globalProgress = 0
        this.z = 0
        this.currentImg = 1
    }

    checkImg(perspective, chronomanager, timeline, scoremanager) {
        if (this.globalProgress < perspective && this.currentImg == 2) {
            this.currentImg = 1
            scoremanager.updateScore(0, 0)
            timeline.update(0)
            chronomanager.updateChrono(0)
        } else if (
            this.globalProgress > perspective &&
            this.globalProgress < perspective * 2 &&
            (this.currentImg == 1 || this.currentImg == 3)
        ) {
            this.currentImg = 2
            chronomanager.updateChrono(60 * 18)
            scoremanager.updateScore(1, 0)

            timeline.update(110)
        } else if (
            this.globalProgress > perspective * 2 &&
            this.globalProgress < perspective * 3 &&
            (this.currentImg == 2 || this.currentImg == 4)
        ) {
            this.currentImg = 3
            chronomanager.updateChrono(60 * 28)
            scoremanager.updateScore(1, 1)
            timeline.update(160)
        } else if (
            this.globalProgress > perspective * 3 &&
            this.globalProgress < perspective * 4 &&
            (this.currentImg == 3 || this.currentImg == 5)
        ) {
            this.currentImg = 4
            chronomanager.updateChrono(60 * 36)
            timeline.update(200)
        } else if (
            this.globalProgress > perspective * 4 &&
            this.globalProgress < perspective * 5 &&
            (this.currentImg == 4 || this.currentImg == 6)
        ) {
            this.currentImg = 5
            scoremanager.updateScore(2, 1)
            chronomanager.updateChrono(60 * 38)
            timeline.update(210)
        } else if (
            this.globalProgress > perspective * 5 &&
            this.globalProgress < perspective * 6 &&
            (this.currentImg == 5 || this.currentImg == 7)
        ) {
            this.currentImg = 6
            chronomanager.updateChrono(60 * 45)
            timeline.update(260)
        } else if (
            this.globalProgress > perspective * 6 &&
            this.globalProgress < perspective * 7 &&
            (this.currentImg == 6 || this.currentImg == 8)
        ) {
            this.currentImg = 7
            scoremanager.updateScore(3, 1)
            chronomanager.updateChrono(60 * 59)
            timeline.update(330)
        } else if (
            this.globalProgress > perspective * 7 &&
            this.globalProgress < perspective * 8 &&
            (this.currentImg == 7 || this.currentImg == 9)
        ) {
            this.currentImg = 8
            scoremanager.updateScore(4, 1)
            chronomanager.updateChrono(60 * 65)
            timeline.update(360)
        } else if (
            this.globalProgress > perspective * 8 &&
            this.globalProgress < perspective * 9 &&
            (this.currentImg == 8 || this.currentImg == 10)
        ) {
            this.currentImg = 9
            scoremanager.updateScore(4, 2)
            chronomanager.updateChrono(60 * 69)
            timeline.update(380)
        } else if (
            this.globalProgress > perspective * 9 &&
            this.globalProgress < perspective * 10 &&
            this.currentImg == 9
        ) {
            this.currentImg = 10
            chronomanager.updateChrono(60 * 90)
            timeline.update(500)
        }
    }
}
