import { TweenMax } from "gsap"
import { addWheelListener } from "./AddWheelListener.js"

import AudioManager from "./AudioManager"
import ScoreManager from "./ScoreManager"
import TimeLine from "./TimeLine"
import ChronoManager from "./ChronoManager"
import ImgManager from "./ImgManager"
import Loader from "./Loader"

import Chart1 from "./Charts/Chart1"
import Chart2 from "./Charts/Chart2"
import Chart3 from "./Charts/Chart3"
import Chart4 from "./Charts/Chart4"
import Chart5 from "./Charts/Chart5"
import Chart6 from "./Charts/Chart6"
import Chart7 from "./Charts/Chart7"
import Chart8 from "./Charts/Chart8"
import Chart9 from "./Charts/Chart9"
import Chart10 from "./Charts/Chart10"

const loader = new Loader()

const chart1 = new Chart1()
const chart2 = new Chart2()
const chart3 = new Chart3()
const chart4 = new Chart4()
const chart5 = new Chart5()
const chart6 = new Chart6()
const chart7 = new Chart7()
const chart8 = new Chart8()
const chart9 = new Chart9()
const chart10 = new Chart10()

const perspective = 1000
let device = null
let started = false
let titleHidden = false

const audiomanager = new AudioManager(perspective)
const scoremanager = new ScoreManager()
const timeline = new TimeLine()
const chronomanager = new ChronoManager()
const imgmanager = new ImgManager()

const intervals = [
    { currentImage: 1, start: 500, end: 750, offset: 0 },
    { currentImage: 2, start: 1250, end: 1500, offset: 250 },
    { currentImage: 3, start: 2150, end: 2400, offset: 500 },
    { currentImage: 4, start: 3100, end: 3350, offset: 750 },
    { currentImage: 5, start: 4100, end: 4350, offset: 1000 },
    { currentImage: 6, start: 5100, end: 5350, offset: 1250 },
    { currentImage: 7, start: 6100, end: 6350, offset: 1500 },
    { currentImage: 8, start: 7100, end: 7350, offset: 1750 },
    { currentImage: 9, start: 8100, end: 8350, offset: 2000 },
    { currentImage: 10, start: 9100, end: 9350, offset: 2250 }
]

function intro() {
    document.querySelector(".user-anim").classList.add("fade-in")

    document.getElementById("start").addEventListener("click", () => {
        document.querySelector("video").classList.add("fade-out")
        document.querySelector("#start").classList.add("fade-out")
        setTimeout(() => {
            document.querySelector("video").classList.add("hide")
            document.querySelector("#start").classList.add("hide")
            document.querySelector(".home").classList.add("fade-out")
            document.querySelector(".scrollable-content").style.display = "block"
        }, 2000)

        setTimeout(() => {
            document.querySelector(".home").classList.add("hide")
            document.querySelector(".timeline").style.zIndex = 11
            audiomanager.playIntroduction()
            audiomanager.playAmbient()
        }, 3500)
    })
}

intro()
let clickedEvent = null
document.querySelector(".timeline").addEventListener("click", e => {
    // console.log(e.target.classList.contains("event"))

    if (e.target.classList.contains("event")) {
        audiomanager.cancelAll()
        clickedEvent = parseInt(e.target.getAttribute("data-event"))
        document.querySelector(".black").classList.remove("black-fade")
        chart1.transition(false)
        chart2.transition(false)
        chart3.transition(false)
        chart4.transition(false)
        chart5.transition(false)
        chart6.transition(false)
        chart7.transition(false)
        chart8.transition(false)
        chart9.transition(false)
        chart10.transition(false)

        switch (clickedEvent) {
            case 1:
                imgmanager.globalProgress = 0
                imgmanager.z = 0
                imgmanager.currentImg = 1
                chronomanager.updateChrono(0)
                scoremanager.updateScore(0, 0)
                timeline.update(0)
                break
            case 2:
                imgmanager.globalProgress = 1000
                imgmanager.z = 1244
                imgmanager.currentImg = 2
                chronomanager.updateChrono(60 * 18)
                scoremanager.updateScore(1, 0)
                timeline.update(110)
                break
            case 3:
                imgmanager.globalProgress = 2000
                imgmanager.z = 2496
                imgmanager.currentImg = 3

                chronomanager.updateChrono(60 * 28)
                scoremanager.updateScore(1, 1)
                timeline.update(160)
                break
            case 4:
                imgmanager.globalProgress = 3000
                imgmanager.z = 3751
                imgmanager.currentImg = 4

                scoremanager.updateScore(1, 1)
                chronomanager.updateChrono(60 * 36)
                timeline.update(200)
                break
            case 5:
                imgmanager.globalProgress = 4000
                imgmanager.z = 4996
                imgmanager.currentImg = 5

                scoremanager.updateScore(2, 1)
                chronomanager.updateChrono(60 * 38)
                timeline.update(210)
                break
            case 6:
                imgmanager.globalProgress = 5000
                imgmanager.z = 6244
                imgmanager.currentImg = 6

                scoremanager.updateScore(2, 1)
                chronomanager.updateChrono(60 * 45)
                timeline.update(260)
                break
            case 7:
                imgmanager.globalProgress = 6000
                imgmanager.z = 7496
                imgmanager.currentImg = 7

                scoremanager.updateScore(3, 1)
                chronomanager.updateChrono(60 * 59)
                timeline.update(330)
                break
            case 8:
                imgmanager.globalProgress = 7000
                imgmanager.z = 8748
                imgmanager.currentImg = 8

                scoremanager.updateScore(4, 1)
                chronomanager.updateChrono(60 * 65)
                timeline.update(360)
                break
            case 9:
                imgmanager.globalProgress = 8000
                imgmanager.z = 9994
                imgmanager.currentImg = 9

                scoremanager.updateScore(4, 2)
                chronomanager.updateChrono(60 * 69)
                timeline.update(380)
                break
            case 10:
                imgmanager.globalProgress = 9000
                imgmanager.z = 11241
                imgmanager.currentImg = 10

                scoremanager.updateScore(4, 2)
                chronomanager.updateChrono(60 * 90)
                timeline.update(500)
                break
            default:
                break
        }

        scoremanager.updateStyle(imgmanager.currentImg)

        // audiomanager.play(imgmanager.globalProgress)
        // scoremanager.updateStyle(imgmanager.currentImg)
        // chronomanager.
        // timeline.update()

        TweenMax.to(document.querySelector(".container"), 2, {
            z: imgmanager.globalProgress
        })
    }
    // console.log(clickedEvent)

    // update Z
})

addWheelListener(document.querySelector(".scrollable-content"), e => {
    let distance
    e.preventDefault()

    if (e.deltaMode === 1) {
        distance = e.deltaY * 50 * device
    } else {
        distance = e.deltaY * device
    }

    // console.log(distance)

    if (Math.abs(distance) > 0) {
        if (imgmanager.z + distance < 0) {
            distance = -imgmanager.z
        } else if (imgmanager.z + distance > perspective * 13) {
            distance = imgmanager.z - perspective * 13
        }

        if (imgmanager.z > 12400) {
            document.querySelector(".end-screen").style.opacity = 1
            document.querySelector(".score").style.opacity = 0
            document.querySelector(".timeline").style.zIndex = 0
        } else {
            document.querySelector(".end-screen").style.opacity = 0
            document.querySelector(".score").style.opacity = 1
            document.querySelector(".timeline").style.zIndex = 11
        }

        const inInterval = intervals.reduce(
            (acc, cur, index) =>
                imgmanager.z > cur.start + cur.offset &&
                imgmanager.z < cur.end + cur.offset &&
                cur.currentImage == imgmanager.currentImg
                    ? index
                    : acc,
            null
        )

        switch (inInterval) {
            case null:
                imgmanager.globalProgress += distance / 10
                document.querySelector(".black").classList.remove("black-fade")
                chart1.transition(false)
                chart2.transition(false)
                chart3.transition(false)
                chart4.transition(false)
                chart5.transition(false)
                chart6.transition(false)
                chart7.transition(false)
                chart8.transition(false)
                chart9.transition(false)
                chart10.transition(false)
                break
            case 0:
                document.querySelector(".black").classList.add("black-fade")
                chart1.transition(true)
                break
            case 1:
                document.querySelector(".black").classList.add("black-fade")
                chart2.transition(true)
                break
            case 2:
                document.querySelector(".black").classList.add("black-fade")
                chart3.transition(true)
                break
            case 3:
                document.querySelector(".black").classList.add("black-fade")
                chart4.transition(true)
                break
            case 4:
                document.querySelector(".black").classList.add("black-fade")
                chart5.transition(true)
                break
            case 5:
                document.querySelector(".black").classList.add("black-fade")
                chart6.transition(true)
                break
            case 6:
                document.querySelector(".black").classList.add("black-fade")
                chart7.transition(true)
                break
            case 7:
                document.querySelector(".black").classList.add("black-fade")
                chart8.transition(true)
                break
            case 8:
                document.querySelector(".black").classList.add("black-fade")
                chart9.transition(true)
                break
            case 9:
                document.querySelector(".black").classList.add("black-fade")
                chart10.transition(true)
                break

            default:
                break
        }
        // console.log(Math.round(imgmanager.z), Math.round(imgmanager.globalProgress))

        imgmanager.z += distance / 10
        imgmanager.checkImg(perspective, chronomanager, timeline, scoremanager)
        audiomanager.play(imgmanager.globalProgress)
        scoremanager.updateStyle(imgmanager.currentImg)

        TweenMax.to(document.querySelector(".container"), 0.1, {
            z: imgmanager.globalProgress
        })
    }
})

document.querySelector(".loader").addEventListener("click", e => {
    if (e.target.getAttribute("data-device") === "pad") {
        device = 2
        document.querySelector(".user-anim").classList.remove("fade-in")
        document.querySelector(".user-anim").classList.add("remove-pointer-events")
    } else if (e.target.getAttribute("data-device") === "mouse") {
        device = 4
        document.querySelector(".user-anim").classList.remove("fade-in")
        document.querySelector(".user-anim").classList.add("remove-pointer-events")
    }
})

function animate() {
    requestAnimationFrame(animate)
    chronomanager.animateChrono()

    if (device !== null && loader.loading === 100 && started === false) {
        setTimeout(() => {
            started = true
            document.querySelector(".loader").classList.add("fade-out")
            document.getElementById("source").play()
            setTimeout(() => {
                document.querySelector(".loader").classList.add("hide")
                document.getElementById("start").classList.add("fade-in")
            }, 3000)
        }, 2000)
    }

    if (imgmanager.z > 200 && titleHidden === false) {
        titleHidden = true
        document.querySelector(".to-hide").classList.add("fade-out")
        setTimeout(() => {
            document.querySelector(".to-hide").classList.add("hide")
        }, 1000)
    }
}

animate()
