export default class Chart7 {
    constructor() {}
    transition(bool) {
        if (bool) {
            document.querySelector(".chart-8").classList.add("chart-8-trans")
        } else {
            document.querySelector(".chart-8").classList.remove("chart-8-trans")
        }
    }
}
