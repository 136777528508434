export default class ChronoManager {
    constructor() {
        this.current = 0
        this.last = 0
        this.target = 0
        this.isMoving = false
        this.timer = 0
        this.duration = 50

        this.sec = document.getElementById("sec")
        this.min = document.getElementById("min")
    }

    updateChrono(newTarget) {
        this.last = this.current
        this.target = newTarget
        this.isMoving = true
    }

    animateChrono() {
        if (this.isMoving) {
            this.current = this.last + (this.target - this.last) * (this.timer / this.duration)
            let seconds = Math.floor(this.current % 60)
            let minutes = Math.floor(this.current / 60)
            if (seconds < 10) {
                seconds = `0${seconds}`
            }
            if (minutes < 10) {
                minutes = `0${minutes}`
            }
            this.sec.innerHTML = `${seconds}`
            this.min.innerHTML = `${minutes}`
            this.timer++
        }

        if (this.timer > this.duration) {
            this.isMoving = false
            this.timer = 0
        }
    }
}
