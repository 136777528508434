export default class Chart9 {
    constructor() {}

    transition(bool) {
        if (bool) {
            document.querySelector(".chart-10-pie").classList.add("chart-10-trans")
            document.querySelector(".chart-10-bars").classList.add("fade-in")

            document.querySelector(".selected-1").style.width = "300px"
            document.querySelector(".selected-2").style.width = "260px"
            document.querySelector(".selected-3").style.width = "245px"
            document.querySelector(".selected-4").style.width = "228px"
            document.querySelector(".selected-5").style.width = "226px"
            document.querySelector(".selected-6").style.width = "217px"
        } else {
            document.querySelector(".chart-10-pie").classList.remove("chart-10-trans")
            document.querySelector(".chart-10-bars").classList.remove("fade-in")

            document.querySelector(".selected-1").style.width = "0"
            document.querySelector(".selected-2").style.width = "0"
            document.querySelector(".selected-3").style.width = "0"
            document.querySelector(".selected-4").style.width = "0"
            document.querySelector(".selected-5").style.width = "0"
            document.querySelector(".selected-6").style.width = "0"
        }
    }
}
